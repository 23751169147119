<template>
	<div>
		<v-sheet class="dense-inputs">
			<v-row no-gutters>
				<v-col cols="5" lg="5" md="12" xs="12" class="px-1 pb-0 pt-3">
					<v-row no-gutters>
						<v-col cols="5" lg="5" md="3" xs="3">
							<v-select
								:items="searchFields"
								:placeholder="$t('message.searchColumn')"
								autocomplete="off"
								clearable
								prepend-icon="search"
								dense
								hide-details
								solo
								v-model="searchField"
                                @click:clear="resetSearch"
							>
							</v-select>
						</v-col>
						<v-col cols="4" lg="4" md="3" xs="3" class="pl-2">
							<v-text-field
								:placeholder="$t('message.searchTerm')"
								autocomplete="off"
								class="force-text-left"
								clearable
								dense
								hide-details
								solo
								v-model="searchTerm"
								@keyup.enter="searchLcas"
                                @click:clear="resetSearch"
							>
							</v-text-field>
						</v-col>
						<v-col cols="3" lg="3" md="3" xs="3" class="pl-2">
							<v-row no-gutters>
								<v-btn
									:loading="loading.search"
									class="col-6"
									@click="searchLcas"
								>{{ $t('message.search') }}</v-btn>
								<v-btn
									:loading="loading.reset"
									class="col-6"
									@click="resetSearch"
								>{{ $t('message.reset') }}</v-btn>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-sheet>
		<v-overlay
			:value="loading.lcas"
			absolute
			opacity="0.15"
		>
			<v-row>
				<v-col class="text-center">
					<v-progress-circular
						color="primary"
						indeterminate
						size="40"
						width="6"
					></v-progress-circular>
				</v-col>
			</v-row>
		</v-overlay>
		<v-data-table
			:footer-props="{
				itemsPerPageOptions: [10,25,50,-1],
				showFirstLastPage: true
			}"
			:headers="headers"
            :hide-default-header="hideDefaultHeader"
            :hide-default-footer="hideDefaultFooter"
			:items="Lcas"
			:options.sync="tableOptions"
			:server-items-length="totalLcas"
			calculate-widths
			class="mt-3 appic-table-light specification-table"
			dense
			id="letterOfCreditAdviceTable"
			item-key="Lca.id"
		>
<!--			<template v-slot:header="{ props: { headers } }">-->
<!--				<tr>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[0].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[1].text }}</th>-->
<!--                    <th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[2].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[3].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[4].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left width-10-pct font-sm">{{ headers[5].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[6].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[7].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[8].text }}</th>-->
<!--					<th class="grey lighten-3 pa-1 width-1-pct">{{ headers[9].text }}</th>-->
<!--				</tr>-->
<!--			</template>-->
            <template v-slot:item.Lca.Contract.title="{ item }">
                <span class="text-no-wrap font-weight-bold">{{ item.Lca.Contract.version != null ? [item.Lca.Contract.title, item.Lca.Contract.revision_no , item.Lca.Contract.partial_no].filter(Boolean).join('-') : item.Lca.Contract.title }}</span>
            </template>
			<template v-slot:item.Lca.lcadate="{ item }">
				{{ item.Lca.lcadate != '0000-00-00' ? formatDate(item.Lca.lcadate) : '' }}
			</template>
            <template v-slot:item.Lca.Office.title="{ item }">
                {{ item.Lca.Contract.salestype_id == 2 ? item.Lca.Supplier.otsname : item.Lca.Office.title }}
            </template>
			<template v-slot:item.Lca.lcalatestdate="{ item }">
				{{ item.Lca.lcalatestdate != '0000-00-00' ? formatDate(item.Lca.lcalatestdate) : '' }}
			</template>
			<template v-slot:item.Lca.Contract.amount="{ item }">
				<div>{{ lcaAmount(item) }}</div>
			</template>
			<template v-slot:item.Lca.lcalatestdate="{ item }">
				{{ item.Lca.expirydate != '0000-00-00' ? formatDate(item.Lca.expirydate) : '' }}
			</template>
			<template v-slot:item.Lca.expirydate="{ item }">
				{{ item.Lca.expirydate != '0000-00-00' ? formatDate(item.Lca.expirydate) : '' }}
			</template>
			<template v-slot:item.Lca.Loadingport.title="{ item }">
				{{ (item.Lca.Loadingport.title != null ? item.Lca.Loadingport.title  : '') + ( item.Lca.Origin.country != null   ? ' - ' + item.Lca.Origin.country : '') }}
			</template>
			<template v-slot:item.Lca.Shippingport.title="{ item }">
				{{ (item.Lca.Shippingport.title != null ? item.Lca.Shippingport.title  : '') + ( item.Lca.Destination.country != null   ? ' - ' + item.Lca.Destination.country : '') }}
			</template>
			<template v-slot:item.Lca.id="{ item }">
				<v-menu>
					<template v-slot:activator="{ on: menu }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
									<v-icon>more_vert</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('message.moreActions') }}</span>
						</v-tooltip>
					</template>
					<v-list dense>
						<v-list-item class="font-sm" @click="updateLca(item.Lca.id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateLca') }}</v-list-item>
						<v-list-item class="font-sm" @click="cancelLca(item.Lca.id, (item.Lca.Contract.version != null ? [item.Lca.Contract.title, item.Lca.Contract.revision_no , item.Lca.Contract.partial_no].filter(Boolean).join('-') : item.Lca.Contract.title))"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelLca') }}</v-list-item>
						<v-list-item class="font-sm" @click="viewPdf(item.Lca.id)"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>
					</v-list>
				</v-menu>
			</template>
		</v-data-table>
		<SimpleAlert
			:alert_message.sync="dialogs.error_message"
			:dialog.sync="dialogs.error"
			@dialog-closed="dialogClosed"
		></SimpleAlert>
	</div>
</template>

<script>
	import {formatDate} from "Helpers/helpers";
	// import SimpleAlert from "./SimpleAlert";
	import {numberFormat} from "../../helpers/helpers";
	import {mapGetters, mapActions} from "vuex";
	import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import { v4 as uuidv4 } from 'uuid';

    const SimpleAlert = () => import("./SimpleAlert");

	export default {
		name: "LcasListing",
		components: {SimpleAlert},
		data() {
			return {
				dialogs: {
					error:  false,
					error_message: ""
				},
				filterOptions: {
					status: {
						Status: {
							id: 1,
							title: 'open'
						}
					}
				},
                hideDefaultHeader: false,
                hideDefaultFooter: false,
				loading: {
					filter: {
						buyers: false
					},
					search: false,
					lcas: false
				},
				searchField: 'Lca.Contract.title', //default search field
				searchTerm: null,
				tableOptions: {},
			}
		},
		computed: {
			...mapMultiRowFields('lca',{
				Lcas: 'current.Lcas'
			}),
			...mapFields('lca',{
				totalLcas: 'current.totalLcas'
			}),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
			...mapGetters([
				'currencies'
			]),
			formatDate: () => formatDate,
			headers () {
				let headers = [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'Lca.id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 1,
                        text: this.$t('message.forContract'),
                        value: 'Lca.Contract.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false
                    },
					{
						id: 2,
						text: this.$t('message.date'),
						value: 'Lca.lcadate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
                    {
                        id: 3,
                        text: this.$t('message.beneficiary'),
                        value: 'Lca.Office.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
						id: 4,
						text: this.$t('message.bank'),
						value: 'Lca.Bank.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: true,
                        sortable: false
					},
					{
						id: 5,
						text: this.$t('message.amount'),
						value: 'Lca.Contract.amount',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
					{
						id: 6,
						text: this.$t('message.lcaLatestDate'),
						value: 'Lca.lcalatestdate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
					{
						id: 7,
						text: this.$t('message.expiryDate'),
						value: 'Lca.expirydate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
					{
						id: 8,
						text: this.$t('message.portOfLoading'),
						value: 'Lca.Loadingport.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
					{
						id: 9,
						text: this.$t('message.finalDestination'),
						value: 'Lca.Shippingport.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					}
				]
				return headers
			},
			searchFields() {
				return this.headers.filter((header)=>header.searchable === true)
			},
		},
		methods: {
			...mapActions('lca',{
				cancelLcaById: 'cancelLcaById',
				getAllLcas: 'getAllLcas',
				searchAllLcas: 'searchAllLcas'
			}),
			async cancelLca (val, title) {
				if(await this.$root.$confirm(this.$t('message.cancelLca') + ' ' + (title != null ? title : ''), this.$t('message.confirmations.continueLcaCancelAction'), {color: 'orange'})){
					this.cancelLcaById(val).then((status) => {
						if(status == 'done'){
							this.$toast.success(this.$t('message.successes.lcaDeleted'),
								{
                                    classes: ['icon-float-left'],
									icon: 'check_circle_outline'
								}
							)
							this.loadLcas()
						} else {
							this.$toast.error(this.$t('message.errors.lcaNotDeleted'),
								{
                                    classes: ['icon-float-left'],
									icon: 'error_outline'
								}
							)
						}
					})
					.catch(()=>{
						this.$toast.error(this.$t('message.errors.lcaNotDeleted'),
							{
                                classes: ['icon-float-left'],
								icon: 'error_outline'
							}
						)
					})
					.finally(()=>{

					})
				}
			},
			currency(currency_id) {
				return this.currencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
			},
			currencyFormatPrecision (currency) {
				if(isNaN(currency)){
					return this.currencies.find((c) => c.Currency.code == currency)?.Currency?.formatPrecision
				} else {
					return this.currencies.find((c) => c.Currency.id == currency)?.Currency?.formatPrecision
				}
			},
			dialogClosed () {
				this.dialogs.error = false;
				this.dialogs.error_message = ''
			},
			formatThisNumber(value,format){
				return numberFormat(value,format)
			},
			lcaAmount (item) {
				return item.Lca.Contract.currency_id != null ? this.currency(item.Lca.Contract.currency_id) + ' ' + this.formatThisNumber(item.Lca.Contract.amount,this.currencyFormatPrecision(item.Lca.Contract.currency_id)) : ''
			},
			loadLcas () {
				this.loading.lcas = true;
				let payload = {
					tableOptions: this.tableOptions,
					filterOptions: this.filterOptions
				}
				this.getAllLcas(payload)
					.then(()=>{
						this.loading.lcas = false;
					})
					.catch(()=>{
						this.loading.lcas = false;
					})
					.finally(()=>{
						this.loading.lcas = false;
					})
			},
			resetSearch () {
				this.hideDefaultFooter = false
				this.loading.lcas = false
				this.searchField = 'Lca.Contract.title'; //default search field
				this.searchTerm = null;
				this.tableOptions.itemsPerPage = 10
				this.filterOptions = {};
			},
			searchLcas () {
				if(this.searchField == null || this.searchTerm == null){
					this.dialogs.error = true
					this.dialogs.error_message = this.$t('message.errors.noSearchColumnAndSearchTerm')
				} else {
                    //TODO search lcas
                    this.filterOptions = {}
                    this.hideDefaultFooter = true
                    this.loading.ipas = true;
                    this.loading.search = true;
                    let payload = {
                        tableOptions: this.tableOptions,
                        search: {
                            field: this.searchField,
                            value: this.searchTerm
                        }
                    }
                    this.searchAllLcas(payload)
                        .then(() => {
                            this.loading.search = false;
                            this.loading.lcas = false;
                        })
                        .catch(()=>{
                            this.loading.search = false;
                            this.loading.lcas = false;
                        })
                        .finally(()=>{
                            this.loading.search = false;
                            this.loading.lcas = false;
                        })
				}
			},
			updateLca (val) {
                let tab = window.open('/v1/lcas/update/' + val,'_blank')
                tab.name = 'lca_' + val
                this.openedTabs.push(tab.name)
                // router.push({name: 'update_lca', params: { lcaId : val}}).catch(err => {})
			},
			viewPdf (lcaId) {
                let lca = this.Lcas.find( l => l.Lca.id == lcaId)
                let document = encodeURIComponent('LCA ' + (lca.Lca.Contract.version != null ? [lca.Lca.Contract.title, lca.Lca.Contract.revision_no , lca.Lca.Contract.partial_no].filter(Boolean).join('-') : lca.Lca.Contract.title))
				let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
					process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
					+ process.env.VUE_APP_BASE_URL
					+ '/v1/lcas/print/'
					+ lcaId
					+ '/'
					+ uuidv4()
					+ '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
					, "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
			},
		},
		watch: {
			filterOptions: {
				handler(){
					if(!this.loading.search) {
						this.loadLcas()
					}
				},
				deep: true
			},
			tableOptions: {
				handler(){
					this.loadLcas()
				},
				deep: true
			},
		},
		created(){
			let dt = new Date()
			let defaultYear = dt.getFullYear() - 1
			this.loadLcas()
		}
	}
</script>

<style>
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>